// List Icons
// -------------------------

.#{$fa-css-prefix}-ul {
	margin-left: $fa-li-width * 5/4;
	padding-left: 0;
	list-style-type: none;

	> li { position: relative; }
}

.#{$fa-css-prefix}-li {
	position: absolute;
	left: -$fa-li-width;
	width: $fa-li-width;
	line-height: inherit;
	text-align: center;
}
