// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.btn {
	padding-right: 25px;
	padding-left: 25px;
	position: relative;
	display: inline-flex;
	align-items: center;
	min-height: 49px;
	font-family: $font-family-heading;
	font-size: 1.125rem;
	font-weight: 700;
	color: $white;
	border-radius: 30px;
	background-color: $light-pink;
	box-shadow: 2px 2px 12px 0 rgba($black, 0.4);

	&:after {
		margin-left: 10px;
		width: 34px;
		height: 16px;
		color: $light-pink;
		content: "";
		@include background-image(arrow, svg, center center, no-repeat);
	}

	&:hover {
		color: $white;
		background-color: $pink;

		&::after {
			@include background-image(next, svg, center center, no-repeat);
		}
	}

	+ .btn {
		margin-left: 20px;
	}
}


.btn-back {
	&:before {
		margin-left: 10px;
		width: 34px;
		height: 16px;
		color: $light-pink;
		content: "";
		display: inline-block;
		@include background-image(prev, svg, center center, no-repeat);
	}
}
