.faq-overview {
	.faq-items {
		display: none;
	}
	.faq-category .faq-title {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
		background-color: #fff;
		padding: 1rem;
		margin-top: 1rem;
		cursor: pointer;
		border-radius: 8px 8px 0 0;
		a {
			order: 1;
		}
		.faq-icon {
			order: 2;
		}
		h2 {
			font-size: 24px;
			font-weight: 600;
			margin-bottom: -0.5rem !important;
			color: $green;
		}
	}
	.faq-question {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.411);
		padding: 1rem;
		cursor: pointer;

		a {
			order: 1;
		}

		.faq-icon {
			order: 2;
		}

		h3 {
			font-size: 16px;
			font-weight: 900;
			margin-bottom: -0.5rem !important;
		}
	}
	.faq-answer {
		padding: 1rem;
		background-color: rgba(255, 255, 255, 0.2);
		font-weight: 200;
		display: none;
	}
}
