// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	&:before,
	&:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
}

body.default {
	.main {
		ul {

			li {
				&:before {
					display: none;
				}
			}
		}
	}
}

html,
body {
	background-color: $body-bg;
}

body {
	padding-top: 40px;
	@include media-breakpoint-up(md) {
		padding-top: 74px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 95px;
	}

	&#tinymce {
		padding: 25px;

		ul {
			padding-left: 30px;
		}
	}
}

.clear {
	@include clearfix;
}

img {
	max-width: 100%;
}

h1 {
	&:empty {
		display: none;
	}
}

p {
	&:empty {
		display: none;
	}
}

.owl-nav {
	padding: 10px;
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.owl-prev,
	.owl-next {
		color: $pink;
		border: none;
		background-color: transparent;
	}

	.owl-next {
		margin-left: 5px;
	}
}

body.default {
	.main {
		.content {
			ul.custom-list {
				list-style: none;

				li {
					position: relative;
					padding-left: 25px;

					&:before {
						content: '';
						background: url('/images/check-solid.svg');
						background-repeat: no-repeat;
						padding: 9px;
						display: inline-block;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}

body.default .main {
	.toggle-list,
	.list-icons {
		li {
			&:before {
				display: none;
			}
		}
	}
}

.gallery {
	.gallery-caption {
		&:before {
			content: '+';
			margin-right: 5px;
		}
	}
}

ul.list.list-icons {
	list-style: none;
}

	
.landing-page, .two-column-page{
	.bg-dark {
		color: #fff;
		padding-bottom: 2.2rem;
	}
	.container-two-columns{
		.container-holder{
			@media only screen and (min-width: 680px){
			display:flex;
			flex-direction: row;
			gap: 3rem;
			
			.one, .two{
			width: 100%;
			}}
		}
	}
}