.eyecatcher {
	position: relative;
	z-index: 2;
	overflow: hidden;

	&.large {
		margin-top: -98px;

		&:after {
			position: absolute;
			z-index: 2;
			content: "";
		}
	}

	.eyecatcher-container {
		.container-holder {
			margin-left: -15px;
			width: calc(100% + 30px);

			.owl-carousel {
				.item {
					width: 100%;
					height: 230px;
					background-position: center center;
					background-size: cover;
					background-repeat: no-repeat;

					.owl-caption {
						position: absolute;
						left: 15px;
						right: 15px;
						bottom: 100px;
						@include media-breakpoint-up(md) {
							text-align: center;
							bottom: 160px;
						}

						.owl-title {
							color: $white;
							font-size: 36px;
							line-height: 1.2;
							font-family: $font-family-heading;
						}
					}
				}

				&.large {
					.item {
						height: 616px;
						@include media-breakpoint-up(md) {
							height: 680px;
						}
					}
				}
			}
		}
	}

	.owl-nav {
		display: flex;
		bottom: 45%;
		color: white;
		justify-content: space-between;
		font-size: 30px;
	}

	.owl-dots {
		display: none;
	}
}
