
.home-blocks {
	margin-bottom: 130px;
	position: relative;
	z-index: 1;

	&:before {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		width: 100%;
		height: 1025px;
		content: '';
		@include background-image(home-blocks-bg, svg, right center, no-repeat);
	}

	.container-holder {
		text-align: center;

		h1 {
			margin-bottom: 48px;
			text-align: center;
			color: $green;
		}

		.contentblock {
			margin-bottom: 39px;
			display: flex;
			flex-wrap: wrap;

			.item {
				margin-bottom: 15px;
				position: relative;
				width: 100%;

				&:before,
				&:after {
					position: absolute;
					z-index: 2;
					content: '';
				}

				&:before {
					bottom: -1px;
					left: 0;
					width: 100%;
					height: 10px;
					@include background-image(home-blocks-bottom, svg, center center, no-repeat);
				}

				&:after {
					top: 0;
					right: -1px;
					width: 11px;
					height: 100%;
					@include background-image(home-blocks-right, svg, center center, no-repeat);
				}

				&:first-child {
					&:after {
						display: none;
						@include media-breakpoint-up(md) {
							display: block;
						}
					}
				}

				&:nth-child(2n) {
					&:after {
						right: unset;
						left: 0;
						display: block;
						width: 100%;
						height: 10px;
						@include background-image(home-blocks-top, svg, center center, no-repeat);
						@include media-breakpoint-up(md) {
							width: 11px;
							height: 100%;
							@include background-image(home-blocks-left, svg, center center, no-repeat);
						}
					}
				}

				&:nth-child(3n) {
					&:before {
						top: 0;
						bottom: unset;
						@include background-image(home-blocks-top, svg, center center, no-repeat);
					}

					&:after {
						top: unset;
						right: unset;
						bottom: -1px;
						left: 0;
						width: 100%;
						height: 10px;
						@include background-image(home-blocks-bottom, svg, center center, no-repeat);
						@include media-breakpoint-up(md) {
							top: 0;
							right: -1px;
							left: unset;
							width: 11px;
							height: 100%;
							@include background-image(home-blocks-right, svg, center center, no-repeat);
						}
					}
				}

				&:nth-child(4n) {
					&:before {
						top: 0;
						bottom: unset;
						@include background-image(home-blocks-top, svg, center center, no-repeat);
					}

					&:after {
						right: unset;
						left: 0;
						@include background-image(home-blocks-left, svg, center center, no-repeat);
					}
				}

				&:nth-child(even) {
					margin-right: 0;
				}
			}

			&.owl-carousel {
				.owl-nav {
					bottom: 0;

					.owl-prev,
					.owl-next {
						display: block;
						width: 28px;
						height: 13px;
						color: $pink;
						text-indent: -9999px;
					}

					.owl-prev {
						@include background-image(prev, svg, center center, no-repeat);
					}

					.owl-next {
						@include background-image(next-btn, svg, center center, no-repeat);
					}
				}
			}

			+ h1 {
				@extend .h4 !optional;
				margin-bottom: 12px;
			}
		}

		.link {
			@extend .btn !optional;
			margin: {
				right: auto;
				left: auto;
			};
		}
	}
}


@media(max-width: 767px) {
	.content.intro .container-two-columns .container-holder .contentblock .item:nth-child(3n):after {
		bottom: -1px;
	}
	.content.intro .container-two-columns .container-holder .contentblock .item:nth-child(4n):after {
		top: unset;
		bottom: -1px;
		width: 100%;
		height: 10px;
		@include background-image(card-down-bg, svg, center center, no-repeat);
		background-repeat: no-repeat;
		background-position: center center;
	}
}
