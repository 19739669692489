@import 'variables';

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	font-style: normal;
	src: url('#{$fa-font-path}/fa-light-300.eot');
	src: url('#{$fa-font-path}/fa-light-300.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/fa-light-300.woff2') format('woff2'), url('#{$fa-font-path}/fa-light-300.woff') format('woff'), url('#{$fa-font-path}/fa-light-300.ttf') format('truetype'), url('#{$fa-font-path}/fa-light-300.svg#fontawesome') format('svg');
	font-display: $fa-font-display;
}

.fal {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}
