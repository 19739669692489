.mini-sab {
    z-index: 2;
    position: relative;
    .container {
        @extend .d-flex;
        @extend .justify-content-center;
		.container-holder {
			position: relative;
			border-radius: 40px;
			background: $white;
			box-shadow: 0 3px 6px rgba($black, 0.15);
			@include media-breakpoint-down(md) {
				margin-top: -35px;
				padding: 15px;
			}
			@include media-breakpoint-up(lg) {
				min-height: 70px;
				margin-top: -35px;
				padding: 0 35px;
			}
		}
    }
}
.home {
	.mini-sab {
		background: $primary;
		&::before {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			height: 55px;
			@include background-image(banner-bg, svg, center top, repeat-x);		
		}
		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 54px;
			@include background-image(banner-bg, svg, center bottom, repeat-x);		
		}
		.container {
			.container-holder {
				@include media-breakpoint-down(md) {
					margin-top: -85px;
					padding: 15px;
				}
				@include media-breakpoint-up(lg) {
					position: absolute;
					left: auto;
					right: auto;
					bottom: 0;
					margin-top: 0;
					margin-bottom: 15px;
				}
			}
		}
	}
}