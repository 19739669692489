@import 'variables';

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-style: normal;
	src: url('#{$fa-font-path}/fa-solid-900.eot');
	src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'), url('#{$fa-font-path}/fa-solid-900.woff') format('woff'), url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'), url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
	font-display: $fa-font-display;
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
}
