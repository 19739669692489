
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	width: 100vw;
	@include media-breakpoint-up(md) {
		padding: {
			top: 11px;
			bottom: 25px;
		};
	}
	padding: {
		top: 7px;
		bottom: 11px;
	};

	&:before,
	&:after {
		position: absolute;
		left: 0;
		z-index: -1;
		width: 100%;
		background-color: $light-green;
		content: '';
	}

	&:before {
		top: 0;
		height: calc(100% - 25px);
	}

	&:after {
		bottom: 0;
		height: 97px;
		background-color: transparent;
		@include background-image(header-bg, svg, left -455px bottom, repeat-x);
		@include media-breakpoint-up(md) {
			background-position: left -169px bottom;
		}
	}

	.logo {
		position: relative;
		z-index: 1;
		width: 142px;
		@include media-breakpoint-only(md) {
			margin-left: 100px;
		}
		@include media-breakpoint-up(md) {
			width: 200px;
		}
		@include media-breakpoint-up(xl) {
			width: 268px;
		}

		&:before {
			position: absolute;
			bottom: -12px;
			left: 0;
			z-index: -1;
			width: 250px;
			height: 28px;
			content: '';
			@include background-image(logo-bg, png, left -80px bottom, no-repeat);
			@include media-breakpoint-up(md) {
				width: 429px;
				background-position: left -50px bottom;
			}
			@include media-breakpoint-up(xl) {
				background-position: left -10px bottom;
			}
		}
	}

	.navbar {
		padding: 0;
		justify-content: center;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
		}
		@include media-breakpoint-up(xl) {
			padding: 0 50px;
		}
	}

	.menu {
		position: absolute;
		top: 80%;
		left: -15px;
		z-index: -1;
		width: calc(100% + 30px);
		background-color: $light-green;
		@include media-breakpoint-up(md) {
			top: 88%;
		}
		@include media-breakpoint-up(lg) {
			margin-left: auto;
			padding: 0;
			position: relative;
			top: unset;
			left: unset;
			z-index: 0;
			display: block;
			flex-basis: unset;
			flex-grow: unset;
			width: auto;
			background-color: transparent;
		}
		padding: {
			top: 20px;
			right: 15px;
			bottom: 20px;
			left: 15px;
		};
	}

	.navbar-nav {
		margin-bottom: 0;
		display: flex;
		flex-wrap: wrap;
		@include media-breakpoint-up(lg) {
			flex-direction: unset;
		}

		li {
			@include media-breakpoint-up(lg) {
				margin: 0 5px;
			}
			@include media-breakpoint-up(xl) {
				margin: 0 10px;
			}
		}
	}

	.nav-link {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		font-family: $font-family-heading;
		font-size: 15px;
		font-weight: $font-weight-bold;
		color: $white;

		&:hover {
			opacity: 0.7;
		}
	}

	.dropdown-menu {
		border: 0;
		background-color: transparent;
		transition: all 0.3s ease-in-out;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			position: absolute;
			top: 100%;
			left: 0;
			background-color: $light-green;
		}
		padding: {
			top: 0;
			right: 0;
			bottom: 0;
			left: 20px;
		};
	}

	.navbar-collapse {
		&.show {
			+ .menu_toggle {
				span {
					&:before {
						top: 0;
						width: 100%;
						transform: rotate(135deg);
					}

					&:after {
						bottom: 0;
						width: 100%;
						transform: rotate(-135deg);
					}
				}
			}
		}

		&:not(.show) {
			+ .menu_toggle {
				span {
					background: $white;

					&:before {
						top: -8px;
						left: 0;
						transform: rotate(0deg);
					}

					&:after {
						right: 0;
						bottom: -8px;
						transform: rotate(0deg);
					}
				}
			}
		}
	}

	.menu_toggle {
		position: absolute;
		top: 9px;
		left: 0;
		z-index: 15;
		display: block;
		cursor: pointer;
		@include media-breakpoint-up(lg) {
			position: relative;
			top: unset;
			left: unset;
			margin: {
				right: 10px;
				left: auto;
			};
		}
		@include media-breakpoint-up(lg) {
			display: none;
		}

		span {
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			width: 21px;
			height: 3px;
			text-indent: -9999px;
			border-radius: 3px;
			background: none;
			transition: all 0.3s;

			&:before,
			&:after {
				position: absolute;
				display: block;
				width: 100%;
				height: 3px;
				border-radius: 3px;
				background: $white;
				transition: all 0.3s;
				content: '';
			}
		}
	}

	.search-and-book-link {
		position: absolute;
		top: 45px;
		right: 0;
		z-index: 1;
		@include media-breakpoint-up(md) {
			position: relative;
			top: unset;
			right: unset;
			display: block;
		}
		@include media-breakpoint-up(lg) {
			margin: {
				left: 5px;
			};
		}
		@include media-breakpoint-up(xl) {
			margin-left: 15px;
		}
		margin: {
			right: 0;
			bottom: 0;
		};

		li {
			a {
				font-family: $font-family-heading;
				font-size: 0.813rem;
				color: $white;
				border-radius: 20px;
				background-color: $light-pink;
				transition: all 0.3s ease-in-out;
				content: 'Zoek & boek';
				box-shadow: 2px 2px 12px 0 rgba($black, 0.4);
				@include media-breakpoint-up(sm) {
					font-size: 0.938rem;
					padding: {
						top: 7px;
						right: 20px;
						bottom: 6px;
						left: 20px;
					};
				}
				padding: {
					top: 4px;
					right: 13px;
					bottom: 3px;
					left: 13px;
				};

				&:hover {
					text-decoration: none;
					background-color: $pink;
				}
			}
		}
	}

	.site_switcher {
		margin-bottom: 0;
		position: absolute;
		top: 3px;
		right: 5px;
		@include media-breakpoint-up(md) {
			position: relative;
			top: unset;
			right: unset;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		@include media-breakpoint-up(lg) {
			margin: {
				left: 5px;
			};
		}
		@include media-breakpoint-up(xl) {
			margin: {
				left: 10px;
			};
		}

		.navbar-nav {
			display: flex;
			flex-direction: unset;
			flex-wrap: wrap;
			@include media-breakpoint-up(xl) {
				margin: {
					right: 17px;
				}
			}

			li {
				margin: {
					right: 0;
					left: 6px;
				};

				a {
					padding: 0;
					width: 22px;
					height: 22px;
					overflow: hidden;
					border: 1px solid $white;
					border-radius: 50%;
				}
			}
		}
	}

	+ .mini-sab {
		padding-top: 50px;
	}
}
