.footer {
	width: 100%;

	.footer-partners {
		position: relative;
		background-color: $gray-200;
		padding: {
			top: 20px;
			right: 15px;
			bottom: 20px;
			left: 15px;
		}
	}

	.vocabulary_term_list {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		list-style: none;

		li {
			display: flex;
			align-items: center;
			font-size: 0;
			@include media-breakpoint-up(sm) {
				margin: {
					right: 15px;
					left: 15px;
				}
			}
			margin: {
				right: 5px;
				left: 5px;
			}

			img {
				text-indent: 0;
			}

			a {
				i {
					font-size: 2rem;
				}
			}
		}
	}

	.footer-call-to-action {
		padding-top: 81px;
		padding-bottom: 50px;
		position: relative;
		z-index: 1;
		width: 100%;
		background-color: $green;
		overflow: hidden;

		&:before,
		&:after {
			position: absolute;
			z-index: -1;
			width: 100%;
			content: "";
		}

		&:before {
			top: -60px;
			left: 0;
			height: 109px;
			@include background-image(homepage-slider-bg, svg, center center, no-repeat);
		}

		&:after {
			top: 32.2%;
			right: -50px;
			opacity: 0.4;
			width: 135px;
			height: 244px;
			background-size: auto;
			@include background-image(footer-bg1, svg, center center, no-repeat);
			@include media-breakpoint-up(md) {
				right: 5%;
			}
			@include media-breakpoint-up(lg) {
				right: 12.8%;
				opacity: 1;
			}
		}

		.container-holder {
			max-width: 1118px;
			padding: {
				right: 15px;
				left: 15px;
			}
			margin: {
				right: auto;
				left: auto;
			}

			h1 {
				margin-bottom: 28px;
				font-size: 2.25rem;
				color: $white;

				+ p {
					margin-bottom: 40px;
				}

				+ h1 {
					max-width: 500px;
					font-size: 2.4rem;
					font-family: $font-family-heading;
					@include media-breakpoint-up(md) {
						font-size: 3rem;
					}
					margin: {
						right: auto;
						bottom: 6px;
						left: auto;
					}

					+ p {
						margin-bottom: 0;
					}
				}
			}

			p {
				margin-bottom: 0;
				line-height: 1.4;
				color: $white;

				a {
					@extend .btn !optional;
					margin-bottom: 20px;
					@include media-breakpoint-up(sm) {
						margin-bottom: 0;
					}

					+ a {
						margin-left: 0;
						@include media-breakpoint-up(sm) {
							margin-top: 0;
							margin-left: 20px;
						}
					}
				}
			}

			.footer-logo {
				text-align: center;
				margin: auto;
				width: 274px;
				height: 69px;
				@include media-breakpoint-up(sm) {
					width: 378px;
					height: 95px;
				}
			}
		}
	}

	.footer-menu-links {
		padding-top: 20px;
		padding-bottom: 20px;
		width: 100%;
		background-color: $green;

		.container-holder {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			max-width: 1118px;
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
			padding: {
				right: 15px;
				left: 15px;
			}
			margin: {
				right: auto;
				left: auto;
			}
		}
	}

	.footer_list {
		margin-bottom: 20px;
		padding-right: 15px;
		width: 49%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			width: 16.66%;
		}

		h5 {
			margin-bottom: 13px;
			font-size: 1.125rem;
			color: $white;
		}

		.list-item {
			line-height: 1.3;
		}

		.link {
			font-size: 0.875rem;
			color: $white;

			&:hover {
				text-decoration: none;
				color: $light-pink;
			}
		}
	}

	.footer-socket {
		position: relative;
		background-color: $secondary;
		padding: {
			top: 12px;
			bottom: 12px;
		}

		&:before {
			position: absolute;
			top: -24px;
			left: 0;
			width: 100%;
			height: 50px;
			content: "";
			@include background-image(footer-bg, svg, center top, no-repeat);
		}

		.navbar {
			padding: {
				right: 0;
				left: 0;
			}
		}

		.footer_link {
			width: 100%;
		}

		.list {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
		}

		.list-item {
			font-size: 0.75rem;
			@include media-breakpoint-up(sm) {
				margin: {
					right: 10px;
					left: 10px;
				}
			}
			margin: {
				right: 5px;
				left: 5px;
			}

			a {
				color: rgba($white, 0.7);

				&:hover {
					text-decoration: none;
					color: $white;
				}
			}
		}
	}

	.social-icons {
		margin-top: 25px;

		a {
			color: #fff;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}
