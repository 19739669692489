
.card {
	position: relative;
	width: 100%;
	border: 0;
	border-radius: 0;

	.grid & {
		height: 100%;
	}

	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: none;
		opacity: 0.75;
		width: 100%;
		height: 100px;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
		content: '';

		.section-facilities &,
		.home-blocks &,
		.intro & {
			display: block;
		}
	}

	img {
		border-radius: 0;
	}

	.card-image {
		position: relative;

		&:before {
			position: absolute;
			bottom: -2px;
			left: 0;
			display: block;
			width: 100%;
			height: 11px;
			background-size: 100%;
			content: '';
			@include background-image(content-shape, svg, center center, no-repeat);
			@include media-breakpoint-up(sm) {
				bottom: -1px;
			}

			.section-facilities &,
			.home-blocks &,
			.intro & {
				display: none;
			}
		}
	}

	.card-body {
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		line-height: 1.7;
		padding: {
			top: 40px;
		}

		.section-facilities &,
		.home-blocks &,
		.intro & {
			padding: 10px 20px 8px;
			position: absolute;
			top: 0;
			left: 0;
			align-content: flex-end;
			align-items: flex-end;
			line-height: 1.2;
		}

		.home-blocks & {
			padding-bottom: 6px;
		}

		.card-caption {
			padding-bottom: 63px;
			width: 100%;
			font-size: 1rem;
			color: $secondary;

			.section-facilities &,
			.home-blocks &,
			.intro & {
				padding: 0;
				text-align: center;
				color: $white;
			}

			.card-title {
				margin-bottom: 7px;

				.section-facilities &,
				.home-blocks &,
				.intro & {
					@extend .h4 !optional;
					margin-bottom: 0.5rem;
				}
			}

			.card-subtitle {
				@extend .h6 !optional;
			}
		}
	}

	.card-description-content {
		.accommodation_overview &,
		.grid & {
			margin-bottom: 21px;
		}
	}

	.list {
		margin-bottom: 0;

		li {
			padding-left: 30px;
			position: relative;
			font-weight: 500;
			color: $pink;

			i {
				position: absolute;
				top: 3px;
				left: 3px;
				font-size: 1.125rem;
			}
		}
	}

	.card-buttons {
		position: absolute;
		bottom: 26px;
		left: 20px;

		.card-btn {
			@extend .btn !optional;
			padding-top: 3px;
			padding-bottom: 3px;
			min-height: 32px;
			font-size: 1rem;
		}
	}
}
