
.section-facilities {
	padding-top: 110px;
	padding-bottom: 65px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: $gimblet;

	&:before,
	&:after {
		position: absolute;
		left: 0;
		z-index: -1;
		width: 100%;
		content: '';
	}

	&:before {
		bottom: 0;
		height: 265px;
		background-color: $gray-200;
	}

	&:after {
		bottom: 240px;
		height: 109px;
		@include background-image(homepage-slider-bg, svg, center center, no-repeat);
	}

	.container-facilities {
		position: relative;

		&:before {
			position: absolute;
			top: -79px;
			left: -70%;
			z-index: -1;
			width: 100%;
			height: 854px;
			content: '';
			@include background-image(facilities-bg, svg, left center, no-repeat);
			@include media-breakpoint-up(sm) {
				left: -40%;
			}
			@include media-breakpoint-up(md) {
				left: -19%;
			}
			@include media-breakpoint-up(lg) {
				left: -10%;
			}
			@media screen and(min-width:1366px){
				left: 6%;
			}
			@media screen and(min-width:1440px){
				left: 12%;
			}
		}
	}

	.container-holder {
		margin-right: auto;
		margin-left: auto;
		padding-right: 15px;
		padding-left: 15px;
		max-width: 1118px;
		text-align: center;
	}

	h1 {
		margin-bottom: 46px;
		text-align: center;
		color: $white;
	}

	.contentblock {
		margin-bottom: 28px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		@include media-breakpoint-up(md) {
			justify-content: flex-start;
		}

		+ h2 {
			@extend .h4 !optional;
			margin-bottom: 12px;
			text-align: center;
			color: $green;
		}
	}

	.item {
		margin-bottom: 15px;
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 49%;
		}
		@include media-breakpoint-up(md) {
			margin-right: 1.4%;
			width: 32.4%;
		}

		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	.link {
		@extend .btn !optional;
		margin: {
			right: auto;
			left: auto;
		};
	}
}
