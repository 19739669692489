.content {
	position: relative;
	width: 100%;
	padding-top: 60px;

	&.intro {
		margin-bottom: 60px;
		padding-top: 74px;
		padding-right: 15px;
		padding-left: 15px;
		position: relative;
		z-index: 1;
		background-color: $light-pink;
		@include media-breakpoint-up(md) {
			margin-bottom: 93px;
		}
		@include media-breakpoint-up(xl) {
			padding-right: calc((100% - #{1286px}) / 2);
			padding-left: calc((100% - #{1286px}) / 2);
		}

		&:before,
		&:after {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			content: "";
		}

		&:after {
			height: 60px;
			background-color: $gray-200;
		}

		&:before {
			bottom: 15px;
			height: 109px;
			@include background-image(intro-bg, svg, center center, no-repeat);
		}

		h2 {
			color: $white;
		}

		.container-two-columns {
			margin-bottom: 70px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 160px;
			}

			.container-holder {
				margin: unset;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				max-width: unset;
				font-size: 1rem;

				.column {
					&.one {
						margin-bottom: 30px;
						width: 100%;
						font-size: 1.125rem;
						line-height: 1.7;
						text-align: center;
						color: $white;
						@include media-breakpoint-up(lg) {
							margin-bottom: 0;
							padding-top: 51px;
							padding-left: 6px;
							width: 28%;
							text-align: left;
						}

						h1 {
							margin-bottom: 5px;
							color: $white;
						}

						img {
							display: inline-block;
							width: 130px;
							height: auto;
							position: relative;
							@include media-breakpoint-up(xl) {
								width: auto;
							}

							+ img {
								margin-left: 20px;
							}

							// &:nth-of-type(1) {
							// 	z-index: 1;
							// }

							// &:nth-of-type(2) {
							// 	z-index: 3;
							// }

							// &:nth-of-type(3) {
							// 	z-index: 2;
							// 	margin-top: -15px;
							// }
						}
					}

					&.two {
						width: 100%;
						@include media-breakpoint-up(lg) {
							width: 69%;
							padding-top: 51px;
						}
					}
				}

				.contentblock {
					margin: 0;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					width: 100%;

					&:before,
					&:after {
						display: none;
					}

					.item {
						margin-bottom: 10px;
						position: relative;
						width: 100%;
						@include media-breakpoint-up(md) {
							margin: 0;
							padding-right: 5px;
							padding-left: 5px;
							width: 50%;
						}

						&:before,
						&:after {
							position: absolute;
							z-index: 2;
							content: "";
						}

						&:before {
							bottom: -1px;
							left: 0;
							width: 100%;
							height: 11px;
							@include background-image(card-down-bg, svg, center center, no-repeat);
						}

						&:after {
							top: 0;
							right: 0;
							width: 11px;
							height: 100%;
						}

						&:first-child {
							&:before {
								@include background-image(card-down-bg, svg, center center, no-repeat);
							}

							&:after {
								display: none;
								@include background-image(card-right-bg, svg, center center, no-repeat);
								@include media-breakpoint-up(md) {
									display: block;
								}
							}
						}

						&:nth-child(2n) {
							&:after {
								right: unset;
								left: 0;
								width: 100%;
								height: 11px;
								@include background-image(card-top-bg, svg, center center, no-repeat);
								@include media-breakpoint-up(md) {
									width: 11px;
									height: 100%;
									@include background-image(card-left-bg, svg, center center, no-repeat);
								}
							}
						}

						&:nth-child(3n) {
							&:before {
								top: 0;
								bottom: unset;
								@include background-image(card-top-bg, svg, center center, no-repeat);
							}

							&:after {
								top: unset;
								bottom: 0;
								width: 100%;
								height: 11px;
								@include background-image(card-down-bg, svg, center center, no-repeat);
								@include media-breakpoint-up(md) {
									width: 11px;
									height: 100%;
									@include background-image(card-right-bg, svg, center center, no-repeat);
								}
							}
						}
					}
				}

				.owl-carousel {
					position: relative;
					overflow: hidden;

					&:before {
						position: absolute;
						bottom: -70px;
						left: 0;
						z-index: 1;
						width: 100%;
						height: 109px;
						content: "";
						@include background-image(intro-bg, svg, center center, no-repeat);
					}

					.item {
						display: none;
						height: auto;
						background-size: 100%;
						padding: 275px 50px;
						background-repeat: no-repeat;
						@include media-breakpoint-down(lg) {
							padding: 215px 50px;
						}
						@include media-breakpoint-down(sm) {
							padding: 150px 20px;
						}

						&:first-child {
							display: block;
						}
					}

					.owl-nav {
						bottom: 50px;
						z-index: 2;

						.owl-prev,
						.owl-next {
							display: block;
							width: 28px;
							height: 13px;
							color: $pink;
							text-indent: -9999px;
						}

						.owl-prev {
							@include background-image(prev, svg, center center, no-repeat);
						}

						.owl-next {
							@include background-image(next-btn, svg, center center, no-repeat);
						}
					}
				}
			}

			+ .container-two-columns {
				margin-bottom: 0;

				.container-holder {
					align-items: center;

					.column {
						&.one {
							padding: 0;
							order: 2;
							@include media-breakpoint-up(lg) {
								margin-left: -40px;
								order: unset;
								width: calc(63.8% + 40px);
							}
						}

						&.two {
							order: 1;
							width: 100%;
							color: $white;
							@include media-breakpoint-up(lg) {
								order: unset;
								width: 31.5%;
							}

							h1 {
								text-align: right;
								color: $white;
								@include media-breakpoint-up(lg) {
									text-align: left;
								}
							}
						}
					}
				}
			}
		}
	}

	.container-holder {
		max-width: 1088px;
		font-size: 1.125rem;
		margin: {
			right: auto;
			left: auto;
		}

		h1 {
			margin-bottom: 10px;
			color: $green;
		}
	}

	.accommodation_overview,
	.grid:not(.vc-grid-container) {
		margin-bottom: 100px;
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include media-breakpoint-up(lg) {
			justify-content: center;
		}
		padding: {
			top: 89px;
		}

		&:before,
		&:after {
			position: absolute;
			z-index: -1;
			content: "";
		}

		&:before {
			top: 0;
			left: 50%;
			width: calc(100vw);
			height: calc(100% - 150px);
			background-size: 100% 100%;
			transform: translateX(-50%);
			@include background-image(content-bg, svg, no-repeat);
		}

		&:after {
			top: 34px;
			left: -141px;
			width: 346px;
			height: 532px;
			background-size: auto;
			@include background-image(content-bg1, svg, center center, no-repeat);
		}

		.item {
			margin-bottom: 20px;
			width: 100%;
			@include media-breakpoint-up(md) {
				width: 49%;
			}
			@include media-breakpoint-up(lg) {
				margin-right: 1.7%;
				width: 32.2%;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	.search_book {
		margin-top: 45px;
		position: relative;
		z-index: 1;
		min-height: 400px;

		&:before,
		&:after {
			position: absolute;
			z-index: -1;
			content: "";
		}

		&:before {
			top: 35px;
			left: 50%;
			width: calc(100vw);
			height: calc(100% - 150px);
			transform: translateX(-50%);
			@include background-image(content-bg, svg, no-repeat);
		}

		&:after {
			top: 34px;
			left: -141px;
			width: 346px;
			height: 532px;
			background-size: auto;
			@include background-image(content-bg1, svg, center center, no-repeat);
		}
	}

	.accommodation_detail {
		display: flex;
		flex-wrap: wrap;
		font-size: 1rem;

		h1 {
			margin-bottom: 15px;
		}

		.btn-back {
			position: relative;
			display: flex;
			align-items: center;

			&:before {
				display: block;
				width: 28px;
				height: 13px;
				text-indent: 0;
				content: "";
				@include background-image(prev, svg, center center, no-repeat);
			}
		}

		.info {
			margin-bottom: 40px;
			position: relative;
			line-height: 1.7;
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
				padding-right: 3rem;
				flex: 0 0 50%;
				max-width: 50%;
			}

			h1 {
				color: $green;
			}

			h3 {
				@extend .h5 !optional;
				color: $pink;
			}

			p {
				margin-bottom: 26px;
			}

			ul {
				li {
					margin-bottom: 14px;
					position: relative;
					line-height: 1.4;
				}
			}

			.accommodation-properties {
				padding: 0;
				display: flex;
				flex-wrap: wrap;
				margin: {
					bottom: 45px;
				}
				list-style: none;

				li {
					margin-right: 20px;
					margin-bottom: 0;
					padding-left: 30px;
					position: relative;
					font-weight: 500;
					color: $pink;

					&:before {
						display: none;
					}

					i {
						position: absolute;
						top: 3px;
						left: 3px;
						font-size: 1.125rem;
					}
				}
			}
		}

		.info-extra {
			width: 100%;
			@include media-breakpoint-up(lg) {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}

		.tommy {
			margin-top: 80px;
			position: relative;
			z-index: 1;
			width: 100%;
			padding: {
				top: 80px;
				bottom: 140px;
			}

			&:before,
			&:after {
				position: absolute;
				left: 50%;
				z-index: -1;
				transform: translateX(-50%);
				content: "";
			}

			&:before {
				top: 0;
				width: calc(100vw);
				height: 100%;
				background-color: $light-blue;
			}

			&:after {
				top: 295px;
				width: 100vw;
				height: 537px;
				@include background-image(price-bg, svg, center center, no-repeat);
			}

			h2 {
				margin-bottom: 25px;
				text-align: center;
				color: $white;
			}
		}
	}

	.gallery {
		display: flex;
		flex-wrap: wrap;

		img {
			width: 100%;
			height: auto;
		}

		.gallery-item {
			margin-top: 20px;
			padding: 0 10px;
			flex: 0 0 50%;
			max-width: 50%;
			@include media-breakpoint-up(lg) {
				flex: 0 0 33.333%;
				max-width: 33.333%;
			}

			&.large-item {
				margin-top: 0;
				flex: 0 0 100%;
				max-width: 100%;
			}

			&.first-item {
				.gallery-caption {
					display: none;
				}
			}

			&.before-last-item {
				.gallery-caption {
					display: flex;
					@include media-breakpoint-up(lg) {
						display: none;
					}
				}
			}

			&.last-item {
				display: none;
				@include media-breakpoint-up(lg) {
					display: block;
				}

				.gallery-caption {
					display: none;
					@include media-breakpoint-up(lg) {
						display: flex;
					}
				}
			}
		}

		.gallery-link {
			position: relative;
			display: flex;
		}

		.gallery-caption {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			font-size: 1.5rem;
			color: $white;
			background: rgba($black, 0.5);
			@include media-breakpoint-up(sm) {
				font-size: 2rem;
			}
		}
	}

	.service_detail {
		display: flex;
		flex-wrap: wrap;
		font-size: 1rem;

		h1 {
			margin-bottom: 15px;
		}

		.btn-back {
			position: relative;
			display: flex;
			text-indent: -9999px;
			@include media-breakpoint-up(xl) {
				position: absolute;
				top: 22px;
				left: -59px;
			}

			&:before {
				display: block;
				width: 28px;
				height: 13px;
				text-indent: 0;
				content: "";
				@include background-image(prev, svg, center center, no-repeat);
			}
		}

		.info {
			margin-bottom: 40px;
			position: relative;
			line-height: 1.7;
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
				padding-right: 3rem;
				flex: 0 0 50%;
				max-width: 50%;
			}

			h1 {
				color: $green;
			}

			h3 {
				@extend .h5 !optional;
				color: $pink;
			}

			p {
				margin-bottom: 26px;
			}

			ul {
				li {
					margin-bottom: 14px;
					position: relative;
					line-height: 1.4;
				}
			}

			.accommodation-properties {
				padding: 0;
				display: flex;
				flex-wrap: wrap;
				margin: {
					bottom: 45px;
				}

				li {
					margin-right: 20px;
					margin-bottom: 0;
					padding-left: 30px;
					position: relative;
					font-weight: 500;
					color: $pink;

					&:before {
						display: none;
					}

					i {
						position: absolute;
						top: 3px;
						left: 3px;
						font-size: 1.125rem;
					}
				}
			}
		}

		.info-extra {
			width: 100%;
			@include media-breakpoint-up(lg) {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}

		.gallery {
			display: flex;
			flex-wrap: wrap;

			img {
				width: 100%;
				height: auto;
			}

			.gallery-item {
				margin-top: 20px;
				padding: 0 10px;
				flex: 0 0 50%;
				max-width: 50%;
				@include media-breakpoint-up(lg) {
					flex: 0 0 33.333%;
					max-width: 33.333%;
				}

				&.large-item {
					margin-top: 0;
					flex: 0 0 100%;
					max-width: 100%;
				}

				&.first-item {
					.gallery-caption {
						display: none;
					}
				}

				&.before-last-item {
					.gallery-caption {
						display: flex;
						@include media-breakpoint-up(lg) {
							display: none;
						}
					}
				}

				&.last-item {
					display: none;
					@include media-breakpoint-up(lg) {
						display: block;
					}

					.gallery-caption {
						display: none;
						@include media-breakpoint-up(lg) {
							display: flex;
						}
					}
				}
			}

			.gallery-link {
				position: relative;
				display: flex;
			}

			.gallery-caption {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 10;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				font-size: 1.5rem;
				color: $white;
				background: rgba($black, 0.5);
				@include media-breakpoint-up(sm) {
					font-size: 2rem;
				}
			}
		}
	}

	.overview-button {
		@extend .btn !optional;
	}

	.blog_filter {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		.form-control {
			height: 40px;
		}

		.btn {
			margin-left: 10px;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			min-height: unset;

			&:after {
				display: none;
			}
		}
	}

	.blog_overview {
		margin-bottom: 100px;
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include media-breakpoint-up(lg) {
			justify-content: flex-start;
		}
		padding: {
			top: 89px;
		}

		&:before,
		&:after {
			position: absolute;
			z-index: -1;
			content: "";
		}

		&:before {
			top: 0;
			left: 50%;
			width: calc(100vw);
			height: calc(100% - 150px);
			background-size: 100% 100%;
			transform: translateX(-50%);
			@include background-image(content-bg, svg, no-repeat);
		}

		&:after {
			top: 34px;
			left: -141px;
			width: 346px;
			height: 532px;
			background-size: auto;
			@include background-image(content-bg1, svg, center center, no-repeat);
		}

		.item {
			margin-bottom: 20px;
			width: 100%;
			@include media-breakpoint-up(md) {
				width: 49%;
			}
			@include media-breakpoint-up(lg) {
				margin-right: 1.7%;
				width: 32.2%;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	.blog_detail, .service_category_detail {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;

		.info, .info-extra {
			@include make-col(12);
			padding-right: 15px;
			padding-left: 15px;
			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}

		.info-full {
			@include make-col(12);
			padding-right: 15px;
			padding-left: 15px;
		}

		.info {
			@include media-breakpoint-up(lg) {
				padding-right: 3rem !important;
			}

			.btn-back {
				font-weight: 700;
				position: relative;
				padding-left: 15px;
				line-height: 2.5;
				text-decoration: none;

				&:hover {
					color: $primary;

					&::before {
						left: -5px;
					}
				}
			}

			.extra {
				margin-top: 30px;
				color: $blue;
				font-weight: 600;
			}

			h1 {
				font-size: $h3-font-size;
			}
		}

		.info-extra {
			@include media-breakpoint-up(lg) {
				padding-left: 3rem !important;
			}
		}
	}

	.service_category_detail {
	}
}

.content.intro-overview {
	.container-default {
		.container-holder {
			max-width: 730px;
			text-align: center;
			margin: auto auto 50px;
		}
	}
}

body {
	&.home {
		.main {
			&:before {
				position: absolute;
				z-index: 2;
				content: "";
				right: 2px;
				bottom: -90px;
				width: 100%;
				height: 392px;
				background-size: contain;
				pointer-events: none;
				@include background-image(banner-bg2, svg, center center, no-repeat);
				@include media-breakpoint-up(md) {
					bottom: -50px;
					width: 200px;
					height: 340px;
					@include background-image(banner-bg1, svg, center center, no-repeat);
				}
				@include media-breakpoint-up(lg) {
					bottom: 0;
					width: 251px;
					height: 392px;
				}
			}
		}

	}
}

@media only screen and (max-width: 600px) {

	body.home .main:before {
		position: relative;
	}

}
