
.homepage-slider-2 {
	padding-top: 83px;
	position: relative;
	z-index: 2;
	background-color: $light-blue;

	&:before,
	&:after {
		position: absolute;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 109px;
		content: '';
		@include background-image(homepage-slider-bg, svg, center center, no-repeat);
	}

	&:before {
		top: -75px;
	}

	&:after {
		bottom: -20px;
	}

	.container-two-columns {
		padding-right: 15px;
		padding-left: 15px;
		position: relative;
		z-index: 1;
		overflow: hidden;
		@include media-breakpoint-up(xl) {
			padding-right: calc((100% - #{1088px}) / 2);
			padding-left: calc((100% - #{1088px}) / 2);
		}

		&:before {
			position: absolute;
			bottom: -31px;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 149px;
			background-size: cover;
			content: '';
			@include background-image(homepage-slider-bg, svg, center center, no-repeat);
		}

		.container-holder {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.one {
			margin-bottom: 40px;
			width: 100%;
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
				width: 30%;
			}

			p {
				@extend .h2 !optional;
				color: $blue;
			}
		}

		.two {
			width: 100%;
			@include media-breakpoint-up(lg) {
				width: 66.2%;
			}
		}

		h1 {
			padding-bottom: 0;
			color: $blue;
		}

		.image_collection {
			position: relative;
			overflow: hidden;
			@include media-breakpoint-up(xl) {
				width: calc(100% + 139px);
			}

			&:before {
				position: absolute;
				bottom: -70px;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 109px;
				content: '';
				@include background-image(homepage-slider-bg, svg, center center, no-repeat);
			}

			.item {
				height: 550px;
				background-size: contain;
				background-repeat: no-repeat;
				margin-left: 1px;
				@include media-breakpoint-up(lg) {
					height: 500px;
				}
				@include media-breakpoint-down(lg) {
					height: 400px;
				}
				@include media-breakpoint-down(sm) {
					height: 300px;
					margin-bottom:20px;
				}
			}

			&.owl-carousel {
				.owl-nav {
					@include media-breakpoint-down(sm) {
						bottom:60px;
					}
					bottom: 40px;
					justify-content: flex-start;

					.owl-prev,
					.owl-next {
						display: block;
						width: 28px;
						height: 13px;
						color: $pink;
						text-indent: -9999px;
					}

					.owl-prev {
						@include background-image(prev-btn1, svg, center center, no-repeat);
					}

					.owl-next {
						@include background-image(next-btn1, svg, center center, no-repeat);
					}
				}
			}
		}

		+ .container-two-columns {
			padding-top: 50px;
			padding-bottom: 50px;
			font-size: 1.125rem;
			line-height: 1.7;
			background-color: $gray-200;
			@include media-breakpoint-up(lg) {
				padding-top: 88px;
				padding-bottom: 88px;
			}

			&:before {
				bottom: 2px;
				left: 25.21%;
				width: 103px;
				height: 133px;
				background-size: auto;
				@include background-image(homepage-slider-bg1, svg, center center, no-repeat);
			}

			.container-holder {
				align-items: flex-start;
			}

			.one {
				margin-bottom: 40px;
				width: 100%;
				@include media-breakpoint-up(lg) {
					margin-bottom: 0;
					padding-right: 20px;
					width: 49.2%;
				}

				p {
					font-family: $font-family-base;
					font-size: 1.125rem;
					line-height: 1.7;
					color: $secondary;
				}
			}

			.two {
				width: 100%;
				@include media-breakpoint-up(lg) {
					padding-right: 20px;
					width: 49.2%;
				}
			}

			h2 {
				@extend .h3 !optional;
				margin-bottom: 14px;
				color: $pink;
			}
		}
	}
}
